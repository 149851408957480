import React from "react"
import { Doughnut } from "react-chartjs-2"
import { useSelector } from "react-redux"
import { Link } from "gatsby"
import { format } from "@/utils"
import Heading from "@/components/Heading"
import * as style from "./style.module.scss"

const TadaTokenomics = () => {
  const theme = useSelector((state) => state.settings.theme)
  const isLight = theme === "default"

  const total = 582550251
  const percentage = {
    seed: 0.1,
    priround: 0.1,
    ido: 0.01,
    founders: 0.19,
    marketing: 0.08,
    providers: 0.1,
    development: 0.15,
    liquid: 0.1,
    delegators: 0.19,
  }
  const seed = parseInt(total * percentage.seed)
  const priround = parseInt(total * percentage.priround)
  const ido = parseInt(total * percentage.ido)
  const founders = parseInt(total * percentage.founders)
  const marketing = parseInt(total * percentage.marketing)
  const providers = parseInt(total * percentage.providers)
  const development = parseInt(total * percentage.development)
  const liquid = parseInt(total * percentage.liquid)
  const delegators = parseInt(total * percentage.delegators)

  const colors = {
    backgroundColor: [
      "rgba(54, 162, 235, 0.6)",
      "rgba(54, 162, 235, 0.6)",
      "rgba(54, 162, 235, 0.6)",
      "rgba(186, 186, 163,0.6)",
      "rgba(255, 206, 86, 0.6)",
      "rgba(232, 289, 86, 0.6)",
      "rgba(217, 106, 86, 0.6)",
      "rgba(155, 246, 86, 0.6)",
      "rgba(149, 96, 86, 0.6)",
    ],
    hoverBackgroundColor: [
      "rgba(54, 162, 235, 0.8)",
      "rgba(54, 162, 235, 0.8)",
      "rgba(54, 162, 235, 0.8)",
      "rgba(186, 186, 163,0.8)",
      "rgba(255, 206, 86, 0.8)",
      "rgba(232, 289, 86, 0.8)",
      "rgba(217, 106, 86, 0.8)",
      "rgba(155, 246, 86, 0.8)",
      "rgba(149, 96, 86, 0.8)",
    ],
    borderColor: [
      "rgba(54, 162, 235, 0.6)",
      "rgba(54, 162, 235, 0.6)",
      "rgba(54, 162, 235, 0.6)",
      "rgba(186, 186, 163,0.6)",
      "rgba(255, 206, 86, 0.6)",
      "rgba(232, 289, 86, 0.6)",
      "rgba(217, 106, 86, 0.6)",
      "rgba(155, 246, 86, 0.6)",
      "rgba(149, 96, 86, 0.6)",
    ],
  }

  const distributionData = {
    labels: [
      "Seed Round",
      "Private Round",
      "IDO",
      "Founders & Team",
      "Marketing",
      "Liquidity Providers",
      "Development Fund",
      "Liquidity mining",
      "Stake Delegators",
    ],
    datasets: [
      {
        data: [
          seed,
          priround,
          ido,
          founders,
          marketing,
          providers,
          development,
          liquid,
          delegators,
        ],
        ...colors,
        borderWidth: 1,
        rotation: 0,
      },
    ],
  }

  const chartOptions = (total) => {
    return {
      maintainAspectRatio: false,
      legend: {
        labels: {
          fontColor: isLight ? "#232135" : "#fff",
        },
      },
      plugins: {
        // labels: {
        //   render: 'value'
        // }
        // datalabels: {
        //   color: isLight ? '#232135' : '#fff',
        //   formatter: (value) => {
        //     return parseInt(value / total * 100) + '%'
        //   }
        // },
      },
      tooltips: {
        callbacks: {
          title: function (tooltipItem, data) {
            return data["labels"][tooltipItem[0]["index"]]
          },
          label: function (tooltipItem, data) {
            return (
              format(data["datasets"][0]["data"][tooltipItem["index"]]) +
              " TADA"
            )
          },
        },
      },
    }
  }

  return (
    <div className="tada__block">
      <Heading id="tokenomics">
        <strong>TADA</strong> tokenomics
      </Heading>
      <div className="tada__left tada__left--dark">
        <h5 className="mb-0">
          <strong>Circulating Supply</strong>
        </h5>
        <div className={`${style.supply} ${style.supplyLarge}`}>
          <strong className="bolder">
            23,538,200{" "}
            <sup>
              <span className="tada__ticker">TADA</span>
            </sup>
          </strong>
        </div>
        <div className="tada__progress mb-4">
          <div style={{ width: `${(23.6 / 406) * 100}%` }} />
        </div>
        <h5 className="mb-1">
          <strong>Max Supply</strong>
        </h5>
        <div className={`${style.supply} mb-5`}>
          <strong className="bolder">
            582,550,251{" "}
            <sup>
              <span className="tada__ticker">TADA</span>
            </sup>
          </strong>
        </div>
        <p>
          A total of 582,550,251 TADA will be issued and will be available over
          a 3-year period. The initial three year allocation is as follows:
        </p>
        <ul className="mb-5">
          <li>
            10.00% to Seed Round{" "}
            <span className="badge badge-token"> {seed} TADA</span>
          </li>
          <li>
            10.00% to Private Round{" "}
            <span className="badge badge-token"> {priround} TADA</span>
          </li>
          <li>
            1.00% to IDO <span className="badge badge-token"> {ido} TADA</span>
          </li>
          <li>
            19.00% to founders, employees, and advisors{" "}
            <span className="badge badge-token"> {founders} TADA</span>
          </li>
          <li>
            8.00% to Marketing{" "}
            <span className="badge badge-token"> {marketing} TADA</span>
          </li>
          <li>
            8.00% to Token Liquidity{" "}
            <span className="badge badge-token"> {providers} TADA</span>
          </li>
          <li>
            15.00% to TADATek development funds{" "}
            <span className="badge badge-token"> {development} TADA</span>
          </li>
          <li>
            10.00% to Liquidity mining{" "}
            <span className="badge badge-token"> {liquid} TADA</span>
          </li>
          <li>
            19.00% to ISPO Rewards{" "}
            <span className="badge badge-token"> {delegators} TADA</span>
          </li>
        </ul>
        <div className="mb-5">
          <Link
            to="/tada/distribution/"
            className="tada__btn tada__btn--round me-3 mb-3"
          >
            Distribution
          </Link>
          <Link
            to="/tada/governance/"
            className="tada__btn tada__btn--round me-3 mb-3"
          >
            Governance
          </Link>
        </div>
      </div>
      {/* <div className="row">
        <div className="col-12 col-sm-6">
          <div className="tada__left tada__left--dark">
            <h5 className="mb-1">
              <strong>
                Stake Delegators <span className="text-shade">19%</span>
              </strong>
            </h5>
            <div className={`${style.supply} mb-1`}>
              <strong className="bolder">
                21,538,200{" "}
                <sup>
                  <span className="tada__ticker">TADA</span>
                </sup>
              </strong>
            </div>
            <div className="tada__progress tada__progress--small mb-3 me-5">
              <div style={{ width: `${21.5 / 101 * 100}%` }} />
            </div>
            <div>
              <strong>Max Allocation</strong>
            </div>
            <div className="mb-5">
              <strong>{delegators}</strong>{" "}
              <span className="tada__ticker">TADA</span>
            </div>
          </div>
          <div className="tada__left tada__left--dark">
            <h5 className="mb-1">
              <strong>
                Liquidity Providers <span className="text-shade">33%</span>
              </strong>
            </h5>
            <div className={`${style.supply} mb-1`}>
              <strong className="bolder">
                0{" "}
                <sup>
                  <span className="tada__ticker">TADA</span>
                </sup>
              </strong>
            </div>
            <div className="tada__progress tada__progress--small mb-3 me-5">
              <div style={{ width: 0 }} />
            </div>
            <div>
              <strong>Max Allocation</strong>
            </div>
            <div className="mb-5">
              <strong>203,892,588</strong>{" "}
              <span className="tada__ticker">TADA</span>
            </div>
          </div>
          <div className="tada__left tada__left--dark">
            <h5 className="mb-1">
              <strong>
                Investors Round <span className="text-shade">20%</span>
              </strong>
            </h5>
            <div className={`${style.supply} mb-1`}>
              <strong className="bolder">
                0{" "}
                <sup>
                  <span className="tada__ticker">TADA</span>
                </sup>
              </strong>
            </div>
            <div className="tada__progress tada__progress--small mb-3 me-5">
              <div style={{ width: 0 }} />
            </div>
            <div>
              <strong>Max Allocation</strong>
            </div>
            <div className="mb-5">
              <strong>116,510,050</strong>{" "}
              <span className="tada__ticker">TADA</span>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6">
          <div className="tada__left tada__left--dark">
            <h5 className="mb-1">
              <strong>
                Development & Marketing Fund <span className="text-shade">14%</span>
              </strong>
            </h5>
            <div className={`${style.supply} mb-1`}>
              <strong className="bolder">
                2,000,000{" "}
                <sup>
                  <span className="tada__ticker">TADA</span>
                </sup>
              </strong>
            </div>
            <div className="tada__progress tada__progress--small mb-3 me-5">
              <div style={{ width: `${2 / 56 * 100}%` }} />
            </div>
            <div>
              <strong>Max Allocation</strong>
            </div>
            <div className="mb-5">
              <strong>70,906,030</strong>{" "}
              <span className="tada__ticker">TADA</span>
            </div>
          </div>
          <div className="tada__left tada__left--dark">
            <h5 className="mb-1">
              <strong>
                Founders Fund <span className="text-shade">8%</span>
              </strong>
            </h5>
            <div className={`${style.supply} mb-1`}>
              <strong className="bolder">
                0{" "}
                <sup>
                  <span className="tada__ticker">TADA</span>
                </sup>
              </strong>
            </div>
            <div className="tada__progress tada__progress--small mb-3 me-5">
              <div style={{ width: 0 }} />
            </div>
            <div>
              <strong>Max Allocation</strong>
            </div>
            <div className="mb-5">
              <strong>46,604,020</strong>{" "}
              <span className="tada__ticker">TADA</span>
            </div>
          </div>
        </div>
      </div> */}
      <div className={style.chart}>
        <div className="text-center">
          <h5 className="mb-2">
            <strong>Planned Distribution Breakdown</strong>
          </h5>
          <div className="mb-3">
            Looking for detailed distribution information?{" "}
            <Link to="/tada/distribution/">Read here.</Link>
          </div>
        </div>
        <div>
          <Doughnut
            data={distributionData}
            options={chartOptions(total)}
            width={300}
            height={300}
          />
        </div>
      </div>
    </div>
  )
}

export default TadaTokenomics
