import React from "react"
import { Helmet } from "react-helmet"
import MainLayout from "@/layouts/Main"
import TadaTokenomics from "@/components/TadaTokenomics"

const Page = () => {
  return (
    <MainLayout>
      <Helmet title="TADA Tokenomics" />
      <TadaTokenomics />
    </MainLayout>
  )
}

export default Page
